.Stickers .sticker {
    height: 300px;
    max-width: 350px;
}

.Stickers .container {
    padding: 0;
    margin: 0 auto;
}

.Stickers h3 {
    text-decoration: underline;
    line-height: 2;
    text-underline-offset: 10px;
}

@media (width < 1020px) {
    .Stickers {
        padding: 0;
        width: 100%;
        height: 100%;
        margin: 0;
    }

    .Stickers .container {
        margin: 0 auto;

    }

    .Stickers h3 {
        line-height: 1;
        font-size: 22px;
    }

    .Stickers .sticker {
        height: 160px;
        max-width: 160px;
        margin: 0 auto;
    }

    .Stickers .col-md-3 {
        max-width: 150px;
        margin: 0 auto;
        padding: 0;


    }

    .Stickers h4 {
        font-size: 16px;
        padding-top: 15px;
    }

    .Stickers .row {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}