:root {
    --font-color: #000000;
    --background: white;
    --nav-background: white;
    --nav-toggle: white;
    --nav-toggle-lines: rgb(255, 255, 255);
    --nav-toggle-active: rgb(153, 153, 153);
    --projects-text: black;
    --projects-background: #E7AF15;
    --hero-background: linear-gradient(180deg, rgba(0, 0, 0, 1) 26%, rgba(255, 255, 255, 1) 100%);
    --hero-image-background: white;
    --hero-image-after: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    --hero-image-color: white;
    --hero-button: #789a5f;
    --footer-color: #d5e4c8;
}

/* 2 */
[data-theme="dark"] {
    --font-color: #eeeeee;
    --background: rgb(60, 60, 60);
    --nav-background: rgb(84, 84, 84);
    --nav-toggle: black;
    --nav-toggle-lines: rgb(181, 181, 181);
    --nav-toggle-active: rgb(217, 217, 217);
    --projects-text: black;
    --projects-background: rgb(192, 128, 40);
    --hero-background: linear-gradient(180deg, rgba(0, 0, 0, 1) 26%, rgb(34, 34, 34) 100%);
    --hero-image-background: rgb(24, 24, 24);
    --hero-image-after: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(60, 60, 60) 100%);
    --hero-image-color: rgb(0, 0, 0);
    --hero-button: rgb(51, 74, 34);
    --footer-color: #445d2e;
}

.App {
    background-color: var(--background);
    color: var(--font-color);
}

.navigator {
    background-color: var(--nav-background);
}

.navigator a {
    color: var(--font-color);
}

.navigator a .active {
    color: var(--nav-toggle-active);
}

.navigator button {
    background-color: var(--nav-toggle-lines);
}

.Projects {
    color: var(--projects-text);
    background-color: var(--projects-background);
}

.Hero2 .container {
    background: var(--hero-background);
}

.Hero2 .container:after {
    background: var(--hero-image-after);
}

.Hero2 img {
    background-color: var(--hero-image-background);
    background-color: var(--hero-image-color);
}

.Hero2 button {
    background-color: var(--hero-button);
}

.AboutPage .topbutton {
    color: var(--font-color);
}

.Footer {
    background-color: var(--footer-color);
}

.Footer a {
    color: var(--font-color);
}

.toggle-theme-wrapper {
    display: flex;
    flex-direction: row;
    gap: 4px;
    margin: 0 auto;
}

.toggle-theme-wrapper span {
    font-size: 28px;
}

.toggle-theme {
    position: relative;
    display: inline-block;
    height: 30px;
    width: 60px;
}

.toggle-theme input {
    display: none;
}

.slider {
    background-color: #ffffffc6;
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition: 0.2s;
    border: 1px solid rgb(199, 199, 199);
}

.slider:before {
    background-color: #67676700;
    bottom: 4px;
    content: "☀️";
    padding: 2px;
    height: 26px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 26px;
}

input:checked+.slider:before {
    transform: translateX(26px);
    content: "🌙";
}

input:checked+.slider {
    background-color: rgba(134, 132, 132, 0.36);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

@media (width < 1020px) {
    .DarkMode {
        margin-left: -30px;
    }

    .slider {
        width: 50px;
        height: 26px;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        transition: 0.2s;
    }

    .toggle-theme-wrapper {
        margin: 0;
        padding: 0;
        height: 100%;
    }

    input {
        width: 40px;
    }

    input:checked+.slider:before {
        transform: translateX(15px);
        font-size: 14px;
    }

    .slider:before {
        font-size: 14px;
        padding: 7px 0 0 0;


    }
}