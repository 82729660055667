.Projects .projectPage {
    width: 90%;
    height: 450px;
    object-fit: cover;
    margin: 0 auto;
    padding: 0;
}

.Projects .grid {
    width: 100%;
    margin: 0;
    padding: 0;
}

.projectsRow img {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.Projects .projectsRow {
    max-width: 100%;
    margin: 0;
    padding: 0;
}

.Projects .cert {
    width: 980px;
    margin: 0 auto;
}

.Projects .container {
    width: 100%;
    height: 100%;
}

.Projects {
    padding: 0;
    max-width: 90%;
    margin: 0 auto;
    mask: radial-gradient(30px at 50px 50px, transparent 98%, black) -50px -50px;
}

.Projects h3 {
    text-decoration: underline;
    line-height: 2;
    text-underline-offset: 10px;
}

.Projects p {
    font-size: 32px;
}

.Projects .skills {
    padding-top: 30px;
}

@media (width < 1020px) {
    .Projects .container {
        padding: 0;
        margin: 0;
        height: 100%;
        width: 100%;
    }

    .Projects .projectPage {
        width: 280px;
        height: 180px;
        padding-bottom: 20px;
    }

    .Projects h3 {
        line-height: 1;
        font-size: 22px;
        padding-top: 20px;
    }

    .Projects .cert {
        width: 350px;
    }

    .Projects p {
        font-size: 18px;
        padding: 0 20px 20px 20px;
    }
}