.navigator .navbar-collapse {
    padding-right: 40px;
}

.navigator button {
    margin-left: 1150px;
}

.navbar-brand {
    width: 70px;
    padding: 0;
    margin: 0;
}

.toggleDark {
    position: absolute;
    left: 50px;
    top: 20px;
}

@media (width < 1020px) {
    .navigator .container {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }

    .nav-link {
        font-size: 12px;
        line-height: 2;
        padding: 0 0 0 40px;
    }

    .navigator button {
        margin: 0 20px 0 0;
    }

    .navbar-brand img {
        height: 40px;
        padding-top: 5px;
        margin-left: 90px;
    }

    .navbar-toggler {
        font-size: 12px;
    }
}

