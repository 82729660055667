.Crafts {
    margin: 0 auto;
    width: 90%;
    padding: 80px 0;
}

.Crafts .close {
    height: 295px;
    padding: 0;
    margin-left: 25px;
}

.Crafts img {
    height: 600px;
    display: flex;
    object-fit: cover;
    border: 1px solid black;
}

.Crafts .closeup1 {
    object-position: center;
    margin-bottom: 10px;
}

.Crafts .wideShot img {
    width: 100%;
    height: 100%;

}

.Crafts h3 {
    text-decoration: underline;
    line-height: 2;
    text-underline-offset: 10px;
}

@media (width < 1020px) {
    .Crafts {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0;
    }

    .Crafts .container {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .Crafts .grid {
        width: 90%;
        margin: 0 auto;
    }

    .Crafts .row {
        margin: 0;
        padding: 0;
        width: 100%;


    }

    .Crafts .wideShot {
        width: 50%;
        margin: 0;
    }

    .Crafts .close {
        margin: 0;
    }

    .Crafts .closeups {
        width: 50%;
        margin: 0;
    }

    .Crafts h3 {
        line-height: 1;
        font-size: 22px;
        padding-bottom: 25px;
        margin: 0;
        width: 100%;
    }


}