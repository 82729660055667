.Footer {
  padding: 30px 30px 0 30px;
  text-align: center;
}

.madeBy p {
  font-size: 10px;
}

.Footer i {
  font-size: 28px;
}

.Footer p {
  padding: 0;
  line-height: 1;
}

.Footer a {
  text-decoration: none;
}

.Footer i:hover {
  color: #c49515;
}

@media (width < 1020px) {
  .Footer .container {
    padding: 0;
    text-align: center;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .Footer a {
    font-size: 8px;
    padding: 0;
    margin: 0 auto;
  }

  .Footer .links {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .Footer .linkTitle {
    width: 20px;
    margin: 0 auto;
    padding: 10px 0;

  }


}