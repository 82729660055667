.App {
  text-align: center;
  font-family: "Julius Sans One", serif;
  font-weight: 400;
  font-style: normal;
}

@media (width < 1020px) {
  .App {
    width: 100%;
    object-fit: fill;
    height: 100%;
    padding: 0;
    margin: 0;
  }
}