.Design .linktree {
    max-width: 175px;
}

.Design .linktreeLhd {
    height: 480px;
    margin: 0 auto;
}

.Design .liveSite {
    padding-right: 180px;
}

.Design .storeText {
    padding-left: 220px;
    padding-top: 20px;
}

.Design .livetext {
    padding-left: 265px;
}

.Design .mockup {
    height: 750px;
    border: 1px solid black;
    margin-left: 60px;
}

.Design .cbdlive {
    width: 250px;
}

.Design .cbdstatic {
    width: 215px;
}

.Design .stores {
    padding-right: 200px;
}

.Design .container-flex {
    width: 95%;
    margin: 0 auto;
}

.Design {
    padding-top: 40px;
}

.Design h3 {
    text-decoration: underline;
    line-height: 2;
    text-underline-offset: 10px;
    padding-bottom: 50px;
}

@media (width < 1020px) {
    .Design {
        margin: 0 auto;

    }

    .Design .container-flex {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }

    .Design .examples {
        max-width: 100%;
        padding: 0;
        margin: 0;
        display: inline-flex;
    }

    .Design .examples .col-md-3 {
        max-width: 25%;
        margin: 0;
        padding: 0;
    }

    .Design .examples img {
        height: 180px;
        margin-bottom: 20px;
    }

    .Design .linktreeLhd {
        height: 240px;
    }

    .Design .lhdDesignRow {
        max-width: 300px;
        padding: 0;
        margin: 0 auto;
        padding-bottom: 40px;
    }

    .Design .liveSite {
        padding-right: 0;
    }

    .Design h3 {
        line-height: 1;
        font-size: 22px;
        padding-bottom: 25px;
    }

    .Design h4 {
        font-size: 16px;
    }

    .Design .mockuptext {
        text-align: start;
        margin-left: -60px;
    }

    .Design .stores {
        margin: 0;
        padding: 0;
    }

    .Design .col-md-4 {
        max-width: 50%;
    }

    .Design .thcstatic {
        max-width: 180px;
        max-height: 500px;
        margin: 0;
    }

    .Design .cbdstatic {
        max-width: 155px;
        max-height: 500px;
        margin: 0;
    }

    .Design .livetext {
        padding: 0;
        margin: 0;
        position: absolute;
        bottom: -700px;
        right: 190px;
    }

    .Design .cbdlive {
        margin-top: 55px;
        margin-left: 100px;
        max-height: 580px;
        max-width: 190px;
    }

    .Design .row {
        width: 100%;
        margin: 0 auto;
    }
}