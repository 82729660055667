.Hero2 .container {
    margin: 0;
    position: absolute;
    padding: 0;
    max-width: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 26%, rgba(255, 255, 255, 1) 100%);
}

.Hero2 {
    margin-bottom: 800px;
}

.Hero2 img {
    z-index: 0;
    width: 100%;
    object-fit: cover;
    height: 750px;
    opacity: 0.4;
    filter: grayscale(.7);


}

.Hero2 .container:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 45%;
}


.Hero2 button {
    position: absolute;
    z-index: 2;
    top: 540px;
    left: 280px;
    padding: 20px 30px;
    border: 3px solid white;
    font-family: "Roboto", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 25px;
    color: white;
    text-decoration: none;
    border-radius: 38px;
}

.Hero2 button:hover {
    background-color: white;
    border: 3px solid #789A5F;
    color: #789A5F;
    transition: all 300ms ease-in-out;
}

.Hero2 .text {
    z-index: 1;
    color: white;
}

.Hero2 .firstName {
    position: absolute;
    top: 180px;
    left: 160px;
    font-size: 100px;
}

.Hero2 .lastName {
    position: absolute;
    top: 320px;
    left: 80px;
    font-size: 100px;
}

.Hero2 .details {
    position: absolute;
    top: 180px;
    right: 200px;

}

.Hero2 h4 {
    font-size: 50px;
}

@media (width < 1020px) {

    .Hero2 {
        margin-bottom: 300px;
        width: 100%;
    }

    .Hero2 img {
        height: 300px;
        max-width: 100%;
    }

    .Hero2 .container:after {
        bottom: -5px;
        height: 35%;
    }

    .Hero2 .firstName {
        top: 90px;
        left: 25px;
        font-size: 24px;
    }

    .Hero2 .lastName {
        top: 120px;
        left: 10px;
        font-size: 24px;
    }

    .Hero2 .details {
        position: absolute;
        top: 70px;
        right: 20px;
    }

    .Hero2 h4 {
        font-size: 14px;
        line-height: 1;
        max-width: 150px;
        padding-bottom: 10px;
    }

    .Hero2 .details p {
        display: none;
    }

    .Hero2 button {
        position: absolute;
        top: 180px;
        left: 20px;
        padding: 5px 10px;
        border: 1px solid white;
        font-size: 12px;
    }

    .Hero2 button:hover {
        border: 1px solid #789A5F;
    }
}